<template>
  <b-overlay
    :show="loading"
    rounded
    opacity="0.6"
    spinner-small
    spinner-variant="primary"
    class="d-inline-block w-100"
  >
    <slot />
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'

export default {
  components: {
    BOverlay
  },
  props: {
    loading: Boolean
  },
  data() {
    return {}
  },
}
</script>