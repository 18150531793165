<template>
  <div>
    <!-- Header -->
    <b-row
      align-h="between"
      align-v="center"
      class="py-2"
    >
      <b-col
        md="5"
        lg="8"
      >
        <!-- Page title -->
        <h2 class="mb-2 mb-md-0">
          Pengguna
        </h2>
      </b-col>
      <b-col
        md="7"
        lg="4"
      >
        <!-- Search -->
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchKeyword"
            placeholder="Cari Nama Pengguna"
          />
        </b-input-group>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-card body-class="p-0">
      <!-- Header -->
      <b-card-header>
        <h4 class="mb-0">
          Daftar Akun Pengguna
        </h4>
        <b-button
          v-b-modal.new-user
          variant="outline-primary"
        >
          <feather-icon icon="PlusIcon" />
          <span>Tambah</span>
        </b-button>
      </b-card-header>

      <!-- Body -->
      <overlay-loading :loading="fetchLoading">
        <b-table
          id="table-transition-gjmf"
          ref="usersTable"
          :items="users"
          :fields="fields"
          class="m-0"
          responsive="sm"
          show-empty
          primary-key="number"
          hover
          :tbody-transition-props="transProps"
        >
          <!-- No Data -->
          <template #empty>
            <p class="mb-0 py-2 text-center">
              Tidak ada data
            </p>
          </template>
          <template #cell(action)="data">
            <div class="d-flex align-items-center">
              <b-button
                v-b-modal.edit-user
                variant="success"
                class="action-button mr-1"
                @click="prepareUpdate(data)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                v-b-modal.delete-user
                variant="danger"
                class="action-button"
                @click="prepareDelete(data)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </overlay-loading>

      <!-- Footer -->
      <b-card-footer>
        <b-row
          align-h="between"
          align-v="center"
        >
          <b-col
            sm="2"
            class="mb-2 mb-sm-0"
          >
            <!-- Select amount of data per page -->
            <b-form-select
              v-model="pagination.selectedAmount"
              :options="pagination.amountOptions"
            />
          </b-col>
          <b-col
            col
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalItems"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              :per-page="pagination.selectedAmount"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <!-- Delete user modal -->
    <b-modal
      id="delete-user"
      title="Hapus"
      cancel-variant="secondary"
      cancel-title="Tidak"
      ok-title="Iya"
      ok-variant="danger"
      body-class="py-2"
      footer-class="d-flex justify-content-center"
      centered
      @ok="onDelete"
    >
      <b-card-text class="text-center">
        <h4 class="mb-0">
          Hapus data?
        </h4>
      </b-card-text>
    </b-modal>

    <!-- New user modal -->
    <b-modal
      id="new-user"
      title="Tambah Pengguna"
      body-class="py-2"
      hide-footer
    >
      <b-card-text>
        <h4 class="mb-2">
          Pengguna
        </h4>
        <validation-observer
          ref="newUserForm"
        >
          <b-form @submit.prevent="onSubmit">
            <!-- Name -->
            <b-form-group
              label="Nama Lengkap"
              label-for="nama"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Lengkap"
                vid="nama"
                rules="required"
              >
                <b-form-input
                  id="nama"
                  v-model="form.add.full_name"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Username TODO TAKEDOWN -->
            <!-- <b-form-group
              label="Username"
              label-for="username"

            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                vid="username"
                rules="required"
              >
              <b-input-group>
                <b-form-input
                  id="username"
                  v-model="form.add.username"
                  type="text"
                  :maxlength="maxUsername"
                  :state="errors.length > 0 ? false:null"
                />
                <b-input-group-append is-text>
                  <span v-text="(maxUsername - form.add.username.length)"></span>
                  <span>&nbsp;Maksimal Karakter</span>
                </b-input-group-append>
              </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- Program Studi -->
            <b-form-group
              label="Program Studi"
              label-for="department"
            >
              <v-select
                id="department"
                v-model="form.add.departments"
                :reduce="department => [{ label: department.label, department_id: department.id }]"
                :options="form.departments"
                :disabled="stateOnReviewer"
              />
            </b-form-group>

            <!-- Email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="form.add.email"
                  type="email"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Phone -->
            <b-form-group
              label="Nomor HP"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Nomor HP"
                vid="phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="form.add.phone"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Hak Akses -->
            <b-form-group
              label="Hak Akses"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="Hak Akses"
                vid="role"
                rules="required"
              >
                <v-select
                  id="role"
                  v-model="form.add.roles"
                  :reduce="role => [{ label: role.label, role_id: role.id }]"
                  :options="form.roles"
                  :state="errors.length > 0 ? false:null"
                  @input="onChangeRoles"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Password -->
            <b-form-group
              label="Kata Sandi"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required|min:8"
              >
                <b-form-input
                  id="password"
                  v-model="form.add.password"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Confirm Password -->
            <b-form-group
              label="Konfirmasi Kata Sandi"
              label-for="confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm password"
                vid="confirm-password"
                rules="required|confirmed:password"
              >
                <b-form-input
                  id="confirm-password"
                  v-model="form.add.confirmPassword"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">
                  {{ errors[0] == 'The Confirm password field confirmation does not match' ? 'Konfirmasi Kata Sandi Tidak Cocok' : '' }}
                </small>
              </validation-provider>
            </b-form-group>

            <!-- Save Pengguna -->
            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="newUserLoading"
                v-text="newUserLoading ? 'Menyimpan...' : 'Simpan'"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- Edit user modal -->
    <b-modal
      id="edit-user"
      title="Ubah Pengguna"
      body-class="py-2"
      hide-footer
    >
      <b-card-text>
        <h4 class="mb-2">
          Pengguna
        </h4>
        <validation-observer
          ref="editUserForm"
        >
          <b-form @submit.prevent="onUpdate">
            <!-- Name -->
            <b-form-group
              label="Nama Lengkap"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                vid="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="form.edit.full_name"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Username TODO TAKEDOWN -->
            <!-- <b-form-group
              label="Username"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                vid="username"
                rules="required"
              >
              <b-input-group>
                <b-form-input
                  id="username"
                  v-model="form.edit.username"
                  type="text"
                  :maxlength="maxUsername"
                  :state="errors.length > 0 ? false:null"
                />
                <b-input-group-append is-text>
                  <span v-text="(maxUsername - form.edit.username.length)"></span>
                  <span>&nbsp;Maksimal Karakter</span>
                </b-input-group-append>
              </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- Program Studi -->
            <b-form-group
              label="Program Studi"
              label-for="department"
            >
              <v-select
                id="department"
                v-model="form.edit.departments"
                :reduce="department => [{ label: department.label, department_id: department.id }]"
                :options="form.departments"
                :disabled="stateOnReviewer"
              />
            </b-form-group>

            <!-- Email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="form.edit.email"
                  type="email"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Phone -->
            <b-form-group
              label="Nomor HP"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Nomor HP"
                vid="phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="form.edit.phone"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Hak Akses -->
            <b-form-group
              label="Hak Akses"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="Hak Akses"
                vid="role"
                rules="required"
              >
                <v-select
                  id="role"
                  v-model="form.edit.roles"
                  :reduce="role => [{ label: role.label, role_id: role.id }]"
                  :options="form.roles"
                  :state="errors.length > 0 ? false:null"
                  @input="onChangeRoles"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Password -->
            <b-form-group
              label="Kata Sandi"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="min:8"
              >
                <b-form-input
                  id="password"
                  v-model="form.edit.new_password"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Confirm Password -->
            <b-form-group
              label="Konfirmasi Kata Sandi"
              label-for="confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm password"
                vid="confirm-password"
                rules="confirmed:password"
              >
                <b-form-input
                  id="confirm-password"
                  v-model="form.edit.confirmPassword"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">
                  {{ errors[0] == 'The Confirm password field confirmation does not match' ? 'Konfirmasi Kata Sandi Tidak Cocok' : '' }}
                </small>
              </validation-provider>
            </b-form-group>

            <!-- Save Pengguna -->
            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="editUserLoading"
                v-text="editUserLoading ? 'Menyimpan...' : 'Simpan'"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardText,
  BTable,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BPagination,
  BFormSelect,
  BModal,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'

import useJwt from '@/auth/jwt/useJwt'
import OverlayLoading from '@/views/components/OverlayLoading.vue'

export default {
  components: {
    Indonesian,
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BTable,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BModal,
    ValidationObserver,
    ValidationProvider,
    VSelect,
    OverlayLoading,
  },
  data() {
    return {
      dpconfig: {
        wrap: true,
        altInput: true,
        // dateFormat: 'Y',
        // altFormat: 'Y',
        // ariaDateFormat: 'Y',
        locale: Indonesian,
        defaultDate: 'today',
        // onOpen : function(event){
        //   let instances = document.getElementsByClassName("flatpickr-innerContainer");
        //   for(let i= 0; i< instances.length; i+=1){
        //     instances[i].style.display="none";
        //   }
        // },
      },
      transProps: {
        name: 'flip-list',
      },
      maxUsername: 15,
      // Form
      form: {
        add: {
          full_name: '',
          // username: '',
          phone: '',
          departments: [],
          email: '',
          roles: [],
          password: '',
          confirmPassword: '',
        },
        edit: {
          id: 0,
          full_name: '',
          // username: '',
          phone: '',
          departments: [],
          email: '',
          roles: [],
          new_password: '',
          confirmPassword: '',
        },
        deleteId: 0,
        departments: [],
        roles: [],
      },
      stateOnReviewer: false,

      // Table fields, data, pagination
      fields: [
        // { label: '#', key: 'number', sortable: true},
        { label: 'Nama', key: 'full_name', sortable: true },
        { label: 'Program Studi', key: 'department', sortable: true },
        { label: 'Email', key: 'email', sortable: true },
        { label: 'Hak Akses', key: 'role', sortable: true },
        { label: 'Aksi', key: 'action' },
      ],
      users: [],
      pagination: {
        amountOptions: [5, 10, 25, 50, 100],
        selectedAmount: 10,
        totalItems: 0,
        currentPage: 1,
      },
      searchKeyword: '',
      timeoutDebounce: null,

      // loadings
      fetchLoading: true,
      newUserLoading: false,
      editUserLoading: false,

      // validation
      required,
    }
  },
  watch: {
    'pagination.currentPage': function () {
      this.fetchUsers()
    },
    'pagination.selectedAmount': function () {
      this.fetchUsers()
    },
    searchKeyword(keyword) {
      if (keyword.length <= 0 || keyword.length >= 3) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetchUsers(keyword), 300)
      }
    },
  },
  created() {
    this.fetchUsers()
    this.fetchDepartments()
    this.fetchRoles()
  },
  methods: {

    fetchUsers(keyword = '') {
      this.fetchLoading = true

      useJwt.http.get('user', {
        params: {
          page: this.pagination.currentPage - 1,
          size: this.pagination.selectedAmount,
          keyword,
        },
      })
        .then(response => {
          this.users = response.data.users.map((user, id) => {
            user.number = id + 1
            user.name = user.full_name
            user.department = user.departments.length ? user.departments[0].program_studi : '-'
            user.role = user.roles.length ? user.roles[0].name : '-'
            return user
          })

          this.pagination.totalItems = response.data.total_items
        })
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => this.fetchLoading = false)
    },
    fetchDepartments() {
      useJwt.http.get('department', {
        params: {
          size: 555,
        },
      })
        .then(response => this.form.departments = response.data.departments.map(({ id, program_studi, lkps_program }) => ({ label: `${program_studi} (${lkps_program.jenis_program})`, id })))
    },
    fetchRoles() {
      useJwt.http.get('role')
        .then(response => this.form.roles = response.data.roles.map(({ id, name: label }) => ({ label, id })))
    },
    onSubmit() {
      this.$refs.newUserForm.validate().then(success => {
        if (success) {
          this.newUserLoading = true

          useJwt.http.post('user', this.form.add)
            .then(response => {
              this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })

              this.form.add.full_name = ''
              // this.form.add.username = ''
              this.form.add.phone = ''
              this.form.add.email = ''
              this.form.add.departments = []
              this.form.add.roles = []
              this.form.add.password = ''
              this.form.add.confirmPassword = ''

              this.$bvModal.hide('new-user')

              this.fetchUsers()
            })
            .catch(() => this.$alert({ title: 'Gagal menambah user.', variant: 'danger', icon: 'XIcon' }))
            .finally(() => this.newUserLoading = false)
        }
      })
    },
    updateDepartment() {
      return useJwt.http.put(
        `user/department/${this.form.edit.id}`,
        {
          departments: this.form.edit.departments.map(department => ({
            department_id: department.department_id,
            user_id: this.form.edit.id,
          })),
        },
      )
    },
    updateRole() {
      return useJwt.http.put(
        `user/role/${this.form.edit.id}`,
        {
          roles: this.form.edit.roles.map(role => ({
            role_id: role.role_id,
            user_id: this.form.edit.id,
          })),
        },
      )
    },
    onUpdate() {
      this.$refs.editUserForm.validate().then(success => {
        if (success) {
          this.editUserLoading = true

          Promise.all([
            useJwt.http.put(`user/${this.form.edit.id}`, this.form.edit),
            !this.stateOnReviewer ? this.updateDepartment() : null,
            this.updateRole(),
          ])
            .then(response => {
              console.log(response)
              this.$alert({ title: 'user updated', variant: 'success', icon: 'CheckIcon' })

              this.form.edit.new_password = ''
              this.form.edit.confirmPassword = ''

              this.fetchUsers()
              this.$bvModal.hide('edit-user')
            })
            .catch(() => this.$alert({ title: 'Gagal mengubah user.', variant: 'danger', icon: 'XIcon' }))
            .finally(() => this.editUserLoading = false)
        }
      })
    },
    prepareUpdate(data) {
      this.form.edit.id = data.item.id
      this.form.edit.full_name = data.item.full_name
      this.form.edit.phone = data.item.phone
      this.form.edit.email = data.item.email

      this.form.edit.roles = data.item.roles.map(role => ({ label: role.name, role_id: role.id }))
      console.log(`these role data : ${this.form.edit.roles[0].label}`)
      if (this.form.edit.roles[0].label.includes('reviewer')) {
        this.stateOnReviewer = true
        this.form.edit.departments = ''
      } else {
        this.stateOnReviewer = false
        this.form.edit.departments = data.item.departments.map(department => ({ label: department.program_studi, department_id: department.id }))
      }
    },
    onDelete() {
      this.fetchLoading = true

      useJwt.http.delete(`user/${this.form.deleteId}/0`)
        .then(response => {
          this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
          this.fetchUsers()
        })
        .catch(() => this.$alert({ title: 'Gagal menghapus user.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => this.fetchLoading = false)
    },
    prepareDelete(data) {
      const { id } = data.item
      this.form.deleteId = id
    },
    onChangeRoles(obj) {
      console.log(`change on ${JSON.stringify(obj.label)}`)
      if (obj[0].label.toString().includes('reviewer')) {
        this.stateOnReviewer = true
        this.form.edit.departments = ''
        this.form.add.departments = ''
      } else this.stateOnReviewer = false
    },
  },
}
</script>
<style>
  table#table-transition-gjmf .flip-list-move {
    transition: transform 0.4s;
  }
</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
